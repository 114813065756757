/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var symbol = function () {
    var appsymbol = null;
     var symbolsiz= null;
    /**
     * Para tratamento de eventos...
     * */
    var eventHandler = function () {
        try 
        {

              var childs= symbolsiz%3;
           if(childs>=1){   
            $(".row.simboly-conteiner > div:nth-last-child(1) hr").addClass("last-cilds");
        }  
        if(childs>=2){   
            $(".row.simboly-conteiner > div:nth-last-child(2) hr").addClass("last-cilds");
        }
        if(childs===0){   
             $(".row.simboly-conteiner > div:nth-last-child(1) hr").addClass("last-cilds");
              $(".row.simboly-conteiner > div:nth-last-child(2) hr").addClass("last-cilds");
            $(".row.simboly-conteiner > div:nth-last-child(3) hr").addClass("last-cilds");
        }
        
            
            
        } catch (e) {
            console.log('[ERRO:symbol]: ' + e);
        }
    };
    return {
        init: function (_appsymbol) {
              
            appsymbol = _appsymbol;
            symbolsiz =$(".row.simboly-conteiner > div").length;
            eventHandler();
        }
    };
}();



